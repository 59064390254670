/* exported numberFormat */
export function numberFormat(number, decimals = 0, decPoint = null, thousandsSep = null) {

    const numberFormat = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: decimals
    });
    console.log(numberFormat.format(number))
    return numberFormat.format(number);

    var s = 0;
    var n, toFixedFix, prec, sep, dec;

    if (typeof(number) === 'undefined') {
        return s;
    }

    number = number.toString().replace(/ /g, '');
    number = number.toString().replace(/,/g, '.');
    s = number;

    if (!isNaN(number)) {
        number = parseFloat(number);
        number += ''.replace(/[^0-9+\-Ee.]/g, '');
        n = !isFinite(Number(number)) ? 0 : Number(number);
        prec = !isFinite(Number(decimals)) ? 0 : Math.abs(decimals);
        sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
        dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
        s = '';

        toFixedFix = function (num, precision) {
            var k = Math.pow(10, precision);
            return String((Math.round(num * k) / k).toFixed(precision));
        };

        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : String(Math.round(n)))
            .split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '')
                .length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1)
                .join('0');
        }
        s = s.join(dec);
    }
    return s;
}

export function isEmpty(target) {

    var type = typeof target;

    switch (type) {

        case "object":
            for (var prop in target) {
                if (target.hasOwnProperty(prop)) {
                    return false;
                }
            }
            break;

        case "array":
            if (target.length > 0) {
                return false;
            }
            break;

        default:
            if (target) {
                return false;
            }
            break;
    }

    return true;
}

    export function capitalize(string, allWords) {

    string = string.toLowerCase();

    //  break down phrase to words then  recursive calls until capitalizing all words
    if (allWords) {
        //return string.split(' ').map(word => capitalize(word, allWords)).join(' ');
    }// if allWords is undefined , capitalize only the first word , mean the first char of the whole string
    else {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}
