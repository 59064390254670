import $ from 'jquery';

export function facebookEventQuotationGenerated()
{
    facebookPushEvent('trackCustom', 'generatedQuotation');
}

$(document).ready(function () {
    let path = document.location.pathname;

    if (path.indexOf('commande/confirmation/') !== -1)
    {
        facebookPushEvent('trackCustom', 'confirmedOrder');
    }

    $('#boutonDevis').click(function () {
        facebookPushEvent('trackCustom', 'quotationAsk');
    });

    $('#boutonCommande').click(function () {
        facebookPushEvent('trackCustom', 'orderAsk');
    });

    $('.callFacebookEvent').click(function () {
        facebookPushEvent('trackCustom', 'Call');
    });

    $('.callBackFacebookEvent').click(function () {
        facebookPushEvent('trackCustom', 'CallBack');
    });
});

function facebookPushEvent(name, identifier) {
    if (typeof fbq !== 'undefined') {
        fbq(name, identifier);
    }
}


