import $ from 'jquery';
import './modernizr-custom';

function setHoverMenu() {

    var subMenu;

    $('header.desktop nav.desktop .panel').hover(
        function (event) {

            subMenu = $(event.currentTarget).children('.containSousSection');

            if ((matchMedia('(min-width: 1300px)').matches)) {

                subMenu
                    .slideDown(600, function () {
                        // On recalcule la position des input sliders pour les onglets de financement (décalage dû au display none de base)
                        $('.inputSlider').slider('relayout');
                    });

            }
            else {
                $('header nav ul li').unbind('mouseenter mouseleave');
                $('header nav ul li .containSousSection')
                    .css('height', 'auto')
                    .css('display', '');
            }

        },
        function (event) {
            subMenu = $(event.currentTarget).children('.containSousSection');

            subMenu
                .stop(true, true)
                .hide();

        }
    );

}

function setMouseEventMenu() {

    // REFAC améliorer pour ne pas faire sur tout le document (/!\ lors du mouseup, on est plus sur le header)
    $(document).mouseup(function () {

        // On unbind au cas ou ça ne serait pas déjà fait
        $('header.desktop nav.desktop .panel').unbind('mouseenter mouseleave');

        setHoverMenu();

    });

    $('header .slider').mousedown(function () {

        $('header.desktop nav.desktop .panel').unbind('mouseenter mouseleave');

    });
}

function switchMenu() {
    if (matchMedia('(min-width: 1300px)').matches) {
        $('header').addClass('desktop')
                   .removeClass('mobile');

        $('header nav').addClass('desktop');
        $('#coverMenu').fadeOut('fast');

        // $('header nav .panel>a').attr('data-toggle','');
        // $('header nav .panel>ul').removeClass('collapse');
        // $('header nav ul li .containSousSection').css('height', 'auto');
    }
    else {
        $('header').addClass('mobile')
                   .removeClass('desktop');
        $('header nav').removeClass('desktop');

        // $('header nav .panel>a').attr('data-toggle','toggle');
        // $('header nav .panel>ul').addClass('collapse');
    }
}


export function toggleMenu() {

    var $headerMobileNav = $('header.mobile nav');
    let $header = $('header');

    // Si le menu est actif
    if ($headerMobileNav.hasClass('is-active')) {

        // On enlève la classe active au menu et à l'icone de menu (croix)
        $headerMobileNav.removeClass('is-active');
        $('body').removeClass('noScroll');

        // On cache le fond bleu translucide
        $('#coverMenu').fadeOut('fast');
    }
    // Sinon on fait l'inverse
    else {
        if ($('.containSearchMobile').hasClass('is-active')) {
            toggleSearchMobile();
        }
        $('#menuInAside').collapse('hide');


        // On calcule la hauteur du menu ( 100 % - hauteur du header)
        // On ajoute la classe active au menu et à l'icone de menu (hamburger)
        if ($header.hasClass('mobile')){
            $headerMobileNav.css({
                height: 'calc(100% - ' + $('header.mobile').outerHeight() + 'px)',
                top: $('header.mobile').outerHeight()
            })
                .addClass('is-active');

            $('body').addClass('noScroll');
        }


        // On fait apparaître le fond bleu translucide
        $('#coverMenu').fadeIn('slow');

        // On recalcule la position des input sliders pour les onglets de financement (décalage dû au display none de base)
        $('.inputSlider').slider('relayout');

    }
}

function initFundingMenuFilter() {
    'use strict';

    var carListUrl = $('#filterVehiclesWithFundingParameters').attr('data-default-url');
    var urlParameters = '';
    var tmpName, tmpValue;

    $('#sliderFilterVehiclesWithFundingParameters').change(function (event) {

        var currentTarget = event.currentTarget;
        tmpName = $(currentTarget).attr('name');
        tmpValue = ($(currentTarget).val()).split(',');

        if ((Number(tmpValue[0]) > Number($(currentTarget).data('slider-min')) || Number(tmpValue[1]) < Number($(currentTarget).data('slider-max')))) {

            urlParameters = '?' + tmpName + '-min=' + tmpValue[0] + '&' + tmpName + '-max=' + tmpValue[1];

            $('#filterVehiclesWithFundingParameters').attr('href', carListUrl + urlParameters);

        }
    });

}
$(document).ready(function () {
    switchMenu();
    setHoverMenu();
    setMouseEventMenu();

    $('#headerHamburger').click(function (e){
        // On appelle la fonction de display du menu
        e.preventDefault();
        toggleMenu();
        $('#headerHamburger .bar').toggleClass('active');
    });

    $('#coverMenu').click(function (e) {
        // On appelle la fonction de display du menu
        e.preventDefault();
        if ($('header.mobile nav').hasClass('is-active')) {
            toggleMenu();
        }
        else if ($('.containSearchMobile').hasClass('is-active')) {
            toggleSearchMobile();
        }
        else {
            $('#menuInAside').collapse('hide');
        }
    });


    $('.menuAccordionHeader').on('shown.bs.collapse', function () {

        // On recalcule la position des input sliders pour les onglets de financement (décalage dû au display none de base)
        $('.inputSlider').slider('relayout');

    });

    $(window).resize(function () {
        $('body').removeClass('noScroll');
        switchMenu();
        setHoverMenu();
        setMouseEventMenu();
    });

    initFundingMenuFilter();

    if (!Modernizr.csscolumns) {
        $('.textColonne')
            .multicolumn()
            .css('font-size', '0.8em');
    }


    if (matchMedia('(max-width: 1299px)').matches) {
        $('nav [data-toggle="collapse"]').click(function () {
            $('.containSousSection').collapse('hide');
        });
    }


    $('#headerSearch').click(function () {
        $('#headerSearch .shape').toggleClass('active');
    });

    if (window.matchMedia('(max-width:768px)').matches) {
        $('.btnMenuCompte').click(function (){
            $('.is-active').animate({
                scrollTop: $('#menuCompteBtn').offset().top - $('header.mobile').outerHeight()
            }, 500);
        });
    }

});
