import $ from 'jquery';
import  * as jcf from 'jcf/dist/js/jcf';

export function openModal(modalHeader, modalBody, modalFooter, modalRole, modalSize) {
    var modalNumber = $('.generatedModal').length;
    var modalId = 'modal-' + modalRole + modalNumber;
    var modalContent = '';
    if (modalSize == 'undefined' || modalSize == null) {
        modalSize = 'modal-lg';
    }

    modalContent += modalHeader.length > 0 ? '<div class = "modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button><h4 class="modal-title">' + modalHeader + '</h4></div>' : '';
    modalContent += modalBody.length > 0 ? '<div class = "modal-body">' + modalBody + '</div>' : '';
    modalContent += modalFooter.length > 0 ? '<div class = "modal-footer">' + modalFooter + '</div>' : '';

    modalContent = '<div class = "modal fade generatedModal" id = "' + modalId + '" role = "' + modalRole + '">' +
        '<div class = "modal-dialog  '+modalSize+'" role = "document">' +
        '<div class = "modal-content">' +
        modalContent +
        '</div>' +
        '</div>' +
        '</div>';

    $('body').append(modalContent);
    $('#' + modalId).modal('show');
    jcf.replaceAll();
}


