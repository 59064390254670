import $ from 'jquery';
// import '../../../../../bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'bootstrap-datepicker';
// import '../../../../../bower_components/bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min';


$(document).ready(function () {

    $.fn.datepicker.defaults.language = 'fr';

    $('.datePicker').datepicker({
        format: 'dd/mm/yyyy',
        language: 'fr',
        autoclose: true,
        toggleActive: true,
        todayHighlight: true
    });

    $('.dmcDatePicker').datepicker({
        format: 'dd/mm/yyyy',
        language: 'fr',
        autoclose: true,
        toggleActive: true,
        todayHighlight: true,
        startView: 2,
        zIndexOffset: 1000,
        orientation: 'bottom auto',
        endDate: 'today'
    });

    $('.datePickerReprise').datepicker({
        format: 'dd/mm/yyyy',
        language: 'fr',
        autoclose: true,
        toggleActive: true,
        todayHighlight: true
    }).on('hide', function (e) {
        e.stopPropagation();
    });
});
