import $ from 'jquery';
import 'bootstrap-slider';

function setInputRange(){
    var kilometrageReprise = $('.inputSlider.kilometrageReprise');

    $('.inputSlider.financementMensualites').slider({
        tooltip: 'always',
        formatter: value => {
            if (value[1] >= 1000) {
                return `${value[0]} € à ${1000} € et +`;
            }

            return `${value[0]} € à ${value[1]} €`;
        },
    });

    $('.inputSlider.financementKilometrageAnnuel').slider({
        tooltip_split: true,
        tooltip: 'always',
        step: 5000,
        formatter: function (value) {
            return value + ' Kms / an';
        },
    });

    $('.inputSlider.electricAutonomy')
        .attr('data-on-deman-unit', 'km')
        .slider({
            tooltip: 'always',
            scale: 'logarithmic',
            formatter: function (value) {
                return value[0] + ' km à ' + value[1] + ' km';
            }
        });

    $('.inputSlider.chargingTime')
        .attr('data-on-deman-unit', 'heure')
        .slider({
            tooltip: 'always',
            scale: 'logarithmic',
            formatter: function (value) {
                return value[0] + 'H à ' + value[1] + 'H';
            }
        });

    $('.inputSlider.prixVehicule')
        .attr('data-on-deman-unit', '€')
        .slider({
            tooltip: 'always',
            scale: 'logarithmic',
            formatter: function (value) {
                return value[0] + ' € à ' + value[1] + ' €';
            }
        });

    if ($('.inputSlider.puissanceCV')[0]){
        $('.inputSlider.puissanceCV')
            .attr('data-on-deman-unit', 'CV')
            .slider({
                tooltip: 'hide',
                formatter: function (value) {
                    return value[0] + ' à ' + value[1] + ' CV';
                }
            }).on('change', function(event){

            var valuesCV = $(this).val();
            var splitValuesCV = valuesCV.split(',');

            $(this).parent().find('.min-slider-handle').html(splitValuesCV[0] + ' CV');
            $(this).parent().find('.max-slider-handle').html(splitValuesCV[1] + ' CV');
        });
    }

    if($('.inputSlider.co2').length > 0){
        $('.inputSlider.co2')
            .attr('data-on-deman-unit', 'g / Km')
            .slider({
                tooltip: 'hide',
                formatter: function (value) {
                    return value[0] + ' à ' + value[1] + ' g / Km';
                }
            }).on('slide change', function (event) {
                let $this = $(event.currentTarget);
                let valuesCo2 = $(event.currentTarget).val();
                let splitValuesCo2 = valuesCo2.split(',');
                $this.parent().find('.min-slider-handle').html(splitValuesCo2[0] + 'g/Km');
                $this.parent().find('.max-slider-handle').html(splitValuesCo2[1] + 'g/Km');

            });
    }

    $('.inputSlider.nbPortes')
        .attr('data-on-deman-unit', 'portes')
        .slider({
            tooltip: 'always',
            formatter: function (value) {
                return value[0] + ' à ' + value[1] + ' portes';
            }
        });

    $('.inputSlider.nbSieges')
        .attr('data-on-deman-unit', 'sièges')
        .slider({
            tooltip: 'always',
            formatter: function (value) {
                return value[0] + ' à ' + value[1] + ' sièges';
            }
        });

    $('.inputSlider.anneeVehicule').slider({
        tooltip: 'always',
        formatter: function (value) {
            return value[0] + ' à ' + value[1];
        }
    });

    $('.inputSlider.kilometrageVehicule').slider({
        tooltip: 'always',
        formatter: function (value) {
            return value[0] + ' à ' + value[1] + ' Kms';
        }
    });

    $('.inputSlider.chargeUtilitaire')
        .attr('data-on-deman-unit', 'Kg')
        .slider({
            tooltip: 'always',
            formatter: function (value) {
                return value[0] + ' à ' + value[1] + 'Kg';
            }
        });

    $('.inputSlider.volumeUtilitaire')
        .attr('data-on-deman-unit', 'm3')
        .slider({
            tooltip: 'always',
            formatter: function (value) {
                return value[0] + ' à ' + value[1] + ' m3';
            }
        });

    kilometrageReprise.slider({
        // tooltip_split: true,
        tooltip: 'always',
        min: 0,
        max: 500000,
        step: 1000,
        value: 250000,
        formatter: function (value) {
            return value + ' Kms';
        }
    });

    kilometrageReprise.on('slide', function (slideEvt){
        $('#kilometrageInputReprise').val(slideEvt.value);
    });

    $('.inputSlider.puissanceCVReprise').slider({
        // tooltip_split: true,
        tooltip: 'always',
        min: 0,
        max: 500,
        step: 1,
        value: 0,
        formatter: function (value) {
            return value +' CV';
        }
    });

}

function initDurationSlider(id, step){
    if($(id).length > 0){
        var slider = $(id).slider({
            tooltip: 'hide',
            step: step,
            formatter: function (value) {
                return value + ' mois';
            }
        }).on('change', function (event) {
            $(event.currentTarget).parent().find('.fundingParameterValue').html($(event.currentTarget).val());
        });

        $(slider.slider('getElement')).addClass('fundingSlider');
        $(id).parent().find('.fundingParameterValue').html($(id).val());
    }
}

$(document).ready(function () {
    setInputRange();

    $('.inputSlider.co2').trigger('change');
    $('.inputSlider.puissanceCV').trigger('change');
});
