import $ from 'jquery';
import 'bootstrap-validator/dist/validator';
import {openModal} from './custom-modal';
import * as jcf from 'jcf/dist/js/jcf';

$(document).ready(function (){
    var forgetPassword = $('.form-forget-password');

    forgetPassword.validator().on('submit', function (e) {
        var $this ='';
        var path = $('#forget-password').attr('data-path');
        var email = '';
        var username = '';
        var cellphone = '';
        var data = {};

        if (e.isDefaultPrevented() === false) {
            e.preventDefault();
            $this = $(e.currentTarget);

            if ($this.attr('id') === 'forget-password'){
                email = forgetPassword.find('input[name="email"]').val();
                data = {
                    email: email,
                    type: 'email'
                };
            }

            if ($this.attr('id') === 'forget-password-with-username') {
                username = forgetPassword.find('input[name="username"]').val();
                cellphone = forgetPassword.find('input[name="cellphone"]').val();
                data = {
                    username: username,
                    cellphone: cellphone,
                    type: 'sms'
                };
            }

            $('.btnResetPassword').hide();
            $('.loadingResetPassword').removeClass('hide');

            $.ajax({
                url: path,
                method: 'POST',
                data: data,
                dataType: 'json',
                success: function (rep) {

                    var $selector;

                    if (typeof rep.statut !== 'undefined') {
                        if (rep.statut === true) {
                            $selector = $('#modalForgotPassword');
                            $selector.modal('hide');
                            openModal('', rep.html, '', 'document');
                        }
                        else {
                            switch (rep.type) {

                                case 'cellphone':
                                    $selector = $('#form-forget-password-with-username');
                                    $selector.html(rep.html);
                                    break;

                                default:
                                    $selector = $('#form-forget-password');
                                    $selector.html(rep.html);
                                    break;
                            }

                            jcf.replaceAll($selector);
                        }
                    }
                }
            });

            return false;
        }
    });

    $(document).on('click', '#checkbox-have-not-email', function (event){
        var $this  = $(event.currentTarget);
        var $mailBlock = $('#form-forget-password');
        var $usernameBlock = $('#form-forget-password-with-username');
        var $cellChkbxBlock = $('#forget-password-cellphone-checkbox-block');
        var $cellChkbx = $('#checkbox-have-not-cellphone');
        var $noCellphoneBlock = $('#forget-password-without-cellphone');

        if ($this.is(':checked')){
            $mailBlock.hide();
            $cellChkbxBlock.show();
            $cellChkbxBlock.removeClass('hide');
            $usernameBlock.removeClass('hide');
            $noCellphoneBlock.removeClass('hide');
            $noCellphoneBlock.hide();
            $usernameBlock.show();
        }
        else {
            $mailBlock.show();
            $cellChkbxBlock.hide();
            $usernameBlock.hide();
            $noCellphoneBlock.hide();
            $cellChkbx.attr('checked', false);
            jcf.refresh($cellChkbx);

        }
    });

    $(document).on('click', '#checkbox-have-not-cellphone', function (event){
        var $this  = $(event.currentTarget);
        var $usernameBlock = $('#form-forget-password-with-username');
        var $noCellphoneBlock = $('#forget-password-without-cellphone');

        if ($this.is(':checked')){
            $usernameBlock.hide();
            $noCellphoneBlock.show();
        }
        else {
            $usernameBlock.show();
            $noCellphoneBlock.hide();
        }
    });

    $(document).on('show.bs.modal', '#modalForgotPassword', function () {
        // on initialise les champs
        var formUserName = $('#form-forget-password-with-username');

        $('#form-forget-password').find('input[name="email"]').val('');
        formUserName.find('input[name="username"]').val('');
        formUserName.find('input[name="cellphone"]').val('');

        $('.loadingResetPassword').addClass('hide');
        $('.btnResetPassword').show();
    });

    $(document).on('hide.bs.modal', '#modalForgotPassword', function () {

        var $checkboxHaveNotEmail = $('#checkbox-have-not-email');

        if ($checkboxHaveNotEmail.is(':checked') ){
            $checkboxHaveNotEmail.click();
        }

    });
});
