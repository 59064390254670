// import toastr from '../../../../../bower_components/toastr/toastr';
import $ from 'jquery';
import toastrNode from 'toastr/build/toastr.min';

export function setToastr(toastr, message, type)
{

    var $toastlast;

    toastr.options = {
        closeButton: true,
        positionClass: 'toast-bottom-right',
        showMethod: 'slideDown',
        hideMethod: 'slideUp',
        closeMethod: 'slideUp',
        timeOut: '8000',
        extendedTimeOut: '1500',
        tapToDismiss: false
    };

    switch (type) {
        case 'success':
            $toastlast = toastr.success(message); // Wire up an event handler to a button in the toast, if it exists
            break;

        case 'error':
            $toastlast = toastr.error(message); // Wire up an event handler to a button in the toast, if it exists
            break;

        case 'warning':
            $toastlast = toastr.warning(message); // Wire up an event handler to a button in the toast, if it exists
            break;

        default:
            $toastlast = toastr.info(message); // Wire up an event handler to a button in the toast, if it exists
            break;
    }

    if ($toastlast.find('.clear').length) {
        $toastlast.delegate('.clear', 'click', function () {
            toastr.clear($toastlast, {force: true});
        });
    }
}

$(document).ready(function () {

    var $toastrElement, toastrMessage, toastrType;

    $('.flashMessageToastr').each(function (index, element) {

        $toastrElement = $(element);

        toastrMessage = $toastrElement.html();
        toastrType = $toastrElement.data('type');

        setToastr(toastrNode, toastrMessage, toastrType);

    });

});
