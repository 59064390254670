import DocumentLoaded from './document-loaded';
import {IntersectionMonitor} from '../utils/intersect-monitor';

let lazyLoadPicture = null;
export default class LazyLoadPicture extends DocumentLoaded {

    constructor() {
        super();
        if (lazyLoadPicture) {
            lazyLoadPicture = this;
        }

        return lazyLoadPicture;
    }

    static create() {
        return new LazyLoadPicture();
    }

    /**
     * @return {LazyLoadPicture}
     */
    static getInstance() {
        return lazyLoadPicture;
    }

    onReady() {
        // this.imgs = document.querySelectorAll('img.lazy');
        this.intersectionMonitor = IntersectionMonitor.create(
            'img.lazy, picture.lazy > source, picture.lazy > img',
            elementDom => {
                LazyLoadPicture.lazyToSrc(elementDom);
                this.intersectionMonitor.unobserve(elementDom);
            },
            null,
            elementDom => {
                LazyLoadPicture.lazyToSrc(elementDom);
            }
        );
    }

    static lazyToSrc(lazyImage) {
        if (lazyImage.dataset.src) {
            lazyImage.src = lazyImage.dataset.src;
        }

        if (lazyImage.dataset.srcset) {
            lazyImage.srcset = lazyImage.dataset.srcset;
        }

        lazyImage.classList.remove('lazy');
        if (lazyImage.parentNode.tagName.toLowerCase() === 'picture') {
            lazyImage.parentNode.classList.remove('lazy');
        }
    }
}
