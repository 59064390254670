export function getCookie(cname) {

    var name = cname + '=';
    var ca = document.cookie.split(';');
    var c, i;

    for (i = 0; i <ca.length; i++) {

        c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}
