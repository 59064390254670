/*! modernizr 3.3.1 (Custom Build) | MIT *
 * https://modernizr.com/download/?-csscolumns-matchmedia-mediaqueries-setclasses !*/
!function (e, n, t) {
    function r(e, n) {
        return typeof e === n
    }

    function o() {
        var e, n, t, o, i, s, a;
        for (var l in C)if (C.hasOwnProperty(l)) {
            if (e = [], n = C[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length))for (t = 0; t < n.options.aliases.length; t++)e.push(n.options.aliases[t].toLowerCase());
            for (o = r(n.fn, "function") ? n.fn() : n.fn, i = 0; i < e.length; i++)s = e[i], a = s.split("."), 1 === a.length ? Modernizr[a[0]] = o : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = o), g.push((o ? "" : "no-") + a.join("-"))
        }
    }

    function i(e) {
        var n = w.className, t = Modernizr._config.classPrefix || "";
        if (_ && (n = n.baseVal), Modernizr._config.enableJSClass) {
            var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
            n = n.replace(r, "$1" + t + "js$2")
        }
        Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), _ ? w.className.baseVal = n : w.className = n)
    }

    function s(e) {
        return e.replace(/([a-z])-([a-z])/g, function (e, n, t) {
            return n + t.toUpperCase()
        }).replace(/^-/, "")
    }

    function a() {
        return "function" != typeof n.createElement ? n.createElement(arguments[0]) : _ ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments)
    }

    function l() {
        var e = n.body;
        return e || (e = a(_ ? "svg" : "body"), e.fake = !0), e
    }

    function u(e, t, r, o) {
        var i, s, u, f, d = "modernizr", c = a("div"), p = l();
        if (parseInt(r, 10))for (; r--;)u = a("div"), u.id = o ? o[r] : d + (r + 1), c.appendChild(u);
        return i = a("style"), i.type = "text/css", i.id = "s" + d, (p.fake ? p : c).appendChild(i), p.appendChild(c), i.styleSheet ? i.styleSheet.cssText = e : i.appendChild(n.createTextNode(e)), c.id = d, p.fake && (p.style.background = "", p.style.overflow = "hidden", f = w.style.overflow, w.style.overflow = "hidden", w.appendChild(p)), s = t(c, e), p.fake ? (p.parentNode.removeChild(p), w.style.overflow = f, w.offsetHeight) : c.parentNode.removeChild(c), !!s
    }

    function f(e, n) {
        return !!~("" + e).indexOf(n)
    }

    function d(e, n) {
        return function () {
            return e.apply(n, arguments)
        }
    }

    function c(e, n, t) {
        var o;
        for (var i in e)if (e[i] in n)return t === !1 ? e[i] : (o = n[e[i]], r(o, "function") ? d(o, t || n) : o);
        return !1
    }

    function p(e) {
        return e.replace(/([A-Z])/g, function (e, n) {
            return "-" + n.toLowerCase()
        }).replace(/^ms-/, "-ms-")
    }

    function m(n, r) {
        var o = n.length;
        if ("CSS" in e && "supports" in e.CSS) {
            for (; o--;)if (e.CSS.supports(p(n[o]), r))return !0;
            return !1
        }
        if ("CSSSupportsRule" in e) {
            for (var i = []; o--;)i.push("(" + p(n[o]) + ":" + r + ")");
            return i = i.join(" or "), u("@supports (" + i + ") { #modernizr { position: absolute; } }", function (e) {
                return "absolute" == getComputedStyle(e, null).position
            })
        }
        return t
    }

    function h(e, n, o, i) {
        function l() {
            d && (delete P.style, delete P.modElem)
        }

        if (i = r(i, "undefined") ? !1 : i, !r(o, "undefined")) {
            var u = m(e, o);
            if (!r(u, "undefined"))return u
        }
        for (var d, c, p, h, v, y = ["modernizr", "tspan", "samp"]; !P.style && y.length;)d = !0, P.modElem = a(y.shift()), P.style = P.modElem.style;
        for (p = e.length, c = 0; p > c; c++)if (h = e[c], v = P.style[h], f(h, "-") && (h = s(h)), P.style[h] !== t) {
            if (i || r(o, "undefined"))return l(), "pfx" == n ? h : !0;
            try {
                P.style[h] = o
            } catch (g) {
            }
            if (P.style[h] != v)return l(), "pfx" == n ? h : !0
        }
        return l(), !1
    }

    function v(e, n, t, o, i) {
        var s = e.charAt(0).toUpperCase() + e.slice(1), a = (e + " " + k.join(s + " ") + s).split(" ");
        return r(n, "string") || r(n, "undefined") ? h(a, n, o, i) : (a = (e + " " + T.join(s + " ") + s).split(" "), c(a, n, t))
    }

    function y(e, n, r) {
        return v(e, t, t, n, r)
    }

    var g = [], C = [], S = {
        _version: "3.3.1",
        _config: {classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0},
        _q: [],
        on: function (e, n) {
            var t = this;
            setTimeout(function () {
                n(t[e])
            }, 0)
        },
        addTest: function (e, n, t) {
            C.push({name: e, fn: n, options: t})
        },
        addAsyncTest: function (e) {
            C.push({name: null, fn: e})
        }
    }, Modernizr = function () {
    };
    Modernizr.prototype = S, Modernizr = new Modernizr;
    var w = n.documentElement, _ = "svg" === w.nodeName.toLowerCase(), x = function () {
        var n = e.matchMedia || e.msMatchMedia;
        return n ? function (e) {
            var t = n(e);
            return t && t.matches || !1
        } : function (n) {
            var t = !1;
            return u("@media " + n + " { #modernizr { position: absolute; } }", function (n) {
                t = "absolute" == (e.getComputedStyle ? e.getComputedStyle(n, null) : n.currentStyle).position
            }), t
        }
    }();
    S.mq = x, Modernizr.addTest("mediaqueries", x("only all"));
    var b = "Moz O ms Webkit", k = S._config.usePrefixes ? b.split(" ") : [];
    S._cssomPrefixes = k;
    var E = function (n) {
        var r, o = prefixes.length, i = e.CSSRule;
        if ("undefined" == typeof i)return t;
        if (!n)return !1;
        if (n = n.replace(/^@/, ""), r = n.replace(/-/g, "_").toUpperCase() + "_RULE", r in i)return "@" + n;
        for (var s = 0; o > s; s++) {
            var a = prefixes[s], l = a.toUpperCase() + "_" + r;
            if (l in i)return "@-" + a.toLowerCase() + "-" + n
        }
        return !1
    };
    S.atRule = E;
    var T = S._config.usePrefixes ? b.toLowerCase().split(" ") : [];
    S._domPrefixes = T;
    var z = {elem: a("modernizr")};
    Modernizr._q.push(function () {
        delete z.elem
    });
    var P = {style: z.elem.style};
    Modernizr._q.unshift(function () {
        delete P.style
    }), S.testAllProps = v;
    var R = S.prefixed = function (e, n, t) {
        return 0 === e.indexOf("@") ? E(e) : (-1 != e.indexOf("-") && (e = s(e)), n ? v(e, n, t) : v(e, "pfx"))
    };
    Modernizr.addTest("matchmedia", !!R("matchMedia", e)), S.testAllProps = y, function () {
        Modernizr.addTest("csscolumns", function () {
            var e = !1, n = y("columnCount");
            try {
                (e = !!n) && (e = new Boolean(e))
            } catch (t) {
            }
            return e
        });
        for (var e, n, t = ["Width", "Span", "Fill", "Gap", "Rule", "RuleColor", "RuleStyle", "RuleWidth", "BreakBefore", "BreakAfter", "BreakInside"], r = 0; r < t.length; r++)e = t[r].toLowerCase(), n = y("column" + t[r]), ("breakbefore" === e || "breakafter" === e || "breakinside" == e) && (n = n || y(t[r])), Modernizr.addTest("csscolumns." + e, n)
    }(), o(), i(g), delete S.addTest, delete S.addAsyncTest;
    for (var N = 0; N < Modernizr._q.length; N++)Modernizr._q[N]();
    e.Modernizr = Modernizr
}(window, document);