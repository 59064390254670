import {EventEmitter} from 'events';

export default class DocumentLoaded extends EventEmitter {

    constructor() {
        super();
        document.addEventListener('DOMContentLoaded', () => {
            this.emit('ready');
            this.onReady();
        });
    }

    onReady() {

    }
}
