// import cookieJs from '../../../../../bower_components/cookies-js/dist/cookies';
import cookieJs from 'cookies-js/dist/cookies';

cookieJs.defaults = {
    expires: 31536000,
    path: '/'
};

export default class CookieManager {

    static setCookie(name, value) {
        if (CookieManager._cookieEnabled()) {
            CookieManager._setCookie(name, value);
        }
    }

    static clearAllCookie() {
        if (CookieManager._cookieEnabled()) {
            // todo: améliorer la suppresion de cookie
            var cookies = document.cookie.split(';');
            var cookie, eqPos, name, i;

            for (i = 0; i < cookies.length; i++) {
                cookie = cookies[i];
                eqPos = cookie.indexOf('=');
                name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
            }
        }
    }

    static cookieEnabled() {
        return CookieManager._cookieEnabled();
    }

    static deleteCookie(name) {
        CookieManager._deleteCookie(name);
    }

    static getCookie(name, encodeJson = false) {
        if (CookieManager._cookieEnabled()) {
            var valueCookie = CookieManager._getCookie(name);
            if (encodeJson === true) {
                return JSON.parse(valueCookie);
            }
            return typeof valueCookie === 'undefined' ? null : valueCookie;
        }
        return null;
    }

    static _setCookie(name, value) {
        cookieJs.set(name, value);
    }

    static _getCookie(name) {
        return cookieJs.get(name);

    }

    static _deleteCookie(name) {
        cookieJs.expire(name);
    }

    static _cookieEnabled() {
        var cookieEnabled = navigator.cookieEnabled;

        if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled)
        {
            document.cookie='testcookie';
            cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
        }
        return cookieEnabled;
    }

}
