import {SearchModule} from '../../modules/search-module';

const FORM_NAME_FILTER_MILEAGE = 'km-max';
const FORM_NAME_FILTER_SEARCH = 'search';
const ID_SEARCH_BUTTON = 'number-available-car';
const SELECTOR_LOADER = '.bsTourSearch .loader-bar-panel';
const SELECTOR_SEARCH_BUTTON = '.bsTourSearch .input-group-btn';

export class SearchPanel {

    constructor(selector) {
        this.selector = selector;
    }

    ready() {
        this.searchBar = new SearchModule(this.selector + ' input[name="search"]');
        if (this.searchBar.ready()) {
            /** @type HTMLFormElement */
            this.form = document.querySelector(this.selector + ' .homeSearch');
            this.events();
        }
    }

    events() {
        this.form.addEventListener('submit', ev => this.onSubmit(ev));
        this.searchBar.on('change', selectedItem => {
            if (this.numberResultDOMElement) {
                if (selectedItem) {
                    this.numberResultDOMElement.textContent = selectedItem.numberResult;
                } else {
                    this.numberResultDOMElement.textContent = this.numberResultDOMElement.dataset.defaultNumber;
                }
            }
            if (window.matchMedia('(max-width: 576px)').matches && selectedItem) {
                this.form.dispatchEvent(new Event('submit', {cancelable: true}));
            }
        });

        this.searchBar.on('loading', () => {
            if (this.form.querySelector(SELECTOR_LOADER)) {
                this.form.querySelector(SELECTOR_LOADER).classList.remove('hidden');
            }
            this.form.querySelector(SELECTOR_SEARCH_BUTTON).classList.add('hidden');
        });
        this.searchBar.on('loaded', () => {
            if (this.form.querySelector(SELECTOR_LOADER)) {
                this.form.querySelector(SELECTOR_LOADER).classList.add('hidden');
            }
            this.form.querySelector(SELECTOR_SEARCH_BUTTON).classList.remove('hidden');
        });
    }

    //@param {{identifiers: {brandSlugify: string, modelSlugify: string}, label: string, numberResult: number }[]} values

    onSubmit(ev) {
        ev.preventDefault();
        if (this.searchBar.isLoading) { // cancel submit if the search bar is loading
            return false;
        }

        if (!this.searchBar.itemSelected) {
            this.searchBar.changeValueInput(0);
        }

        let url = new URL(this.form.action, document.location.origin);
        let values = SearchPanel.serializeArray(this.form);

        // remove empty value
        values = values.filter(value => value.value);

        // remove the search params
        let inputSearch = values.find(input => input.name === FORM_NAME_FILTER_SEARCH);
        values.splice(values.indexOf(inputSearch), 1);

        url.pathname = this.getUrlActionForm(url.pathname, values);

        values.forEach(input => {
            url.searchParams.set(input.name, input.value);
        });

        document.location.href = url.toString();
    }

    /**
     *
     * @param {string} url by default
     * @param {{value:*, name: string}[]} values
     */
    getUrlActionForm(url, values)
    {
        if (!this.searchBar.itemSelected) {
            return '/liste-de-nos-vehicules';
        }

        let inputNew = values.find(value => value.name === FORM_NAME_FILTER_MILEAGE && value.value === 'neuf');
        values.splice(values.indexOf(inputNew), 1);
        let isBrand = typeof this.searchBar.itemSelected.identifiers.modelFrontSlugify === 'undefined';

        if (isBrand) {
            return `/mandataire-${this.searchBar.itemSelected.identifiers.brandSlugify}${inputNew ? '-neuf' : ''}`;
        } else {
            return `/mandataire-${this.searchBar.itemSelected.identifiers.brandSlugify}-${this.searchBar.itemSelected.identifiers.modelFrontSlugify}${inputNew ? '-neuf' : ''}`;
        }
        // return url;
    }

    /**
     * @return {Element}
     */
    get numberResultDOMElement() {
        return this.form.querySelector('.' + ID_SEARCH_BUTTON);
    }

    static serializeArray(form) {
        let field, l, s = [];
        if (typeof form === 'object' && form.nodeName === 'FORM') {
            let len = form.elements.length;
            for (let i=0; i<len; i++) {
                field = form.elements[i];
                if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
                    if (field.type === 'select-multiple') {
                        l = form.elements[i].options.length;
                        for ( j = 0; j < l; j++) {
                            if (field.options[j].selected)
                                s[s.length] = { name: field.name, value: field.options[j].value };
                        }
                    } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                        s[s.length] = { name: field.name, value: field.value };
                    }
                }
            }
        }
        return s;
    }
}
