import $ from 'jquery';

import  {setToastr} from './toastr';
import toastrNode from 'toastr/build/toastr.min';
import {openModal} from './custom-modal';


export function setCarFollowing(toastr) {
    $('.suivreVehicule[data-event-set!=1]').click(function (event) {

        let $followBtn = $(event.currentTarget);
        let $followText = $followBtn.children('.followText');
        let $followersNumber = $followBtn.children('.followersNumber');
        let newFollowersNumber = 0;
        let followClass = $followBtn.attr('data-follow-class');
        let unfollowClass = $followBtn.attr('data-unfollow-class');

        if ($followersNumber.length > 0){
            newFollowersNumber = parseInt($followersNumber.html(), 10);
        }

        $.ajax({
            url: $followBtn.attr('data-path'),
            method: 'POST',
            dataType: 'JSON',
            data: {
                followId: $followBtn.attr('data-id'),
                carId: $followBtn.attr('data-car-id'),
                carBrand: $followBtn.attr('data-car-brand'),
                carModel: $followBtn.attr('data-car-model')
            }
        })
        .done(function (data) {

            if (data.error) {

                if (data.errorHTML.length > 0) {
                    openModal('Connectez-vous pour suivre ce véhicule', '<div class="row">' + data.errorHTML + '</div>', '', 'dialog', 'modal-md');
                    $('.apeCode').val('').removeAttr('minlength').prop('required', false).attr('data-validate', false).validator('update').parent().hide();

                    $('.showFollowCarInscription .btn').click(function () {
                        $('.followCarConnexion').slideUp(function () {
                            $('.showFollowCarConnexion').slideDown();
                            $('.followCarInscription').slideDown();
                            $('.showFollowCarInscription').slideUp();
                        });
                    });

                    $('.showFollowCarConnexion.btn').click(function () {
                        $('.followCarInscription').slideUp(function () {
                            $('.showFollowCarInscription').slideDown();
                            $('.followCarConnexion').slideDown();
                            $('.showFollowCarConnexion').slideUp();
                        });
                    });
                }
                else {
                    setToastr(toastr, 'Oops, votre demande n\'a pu aboutir, veuillez réessayer dans quelques instants', 'error');
                }

            }
            else {
                $followBtn.attr('data-id', data.followId);

                if (data.followId > 0) {
                    $followText.html($followBtn.attr('data-follow-text'));
                    newFollowersNumber++;

                    $followBtn.removeClass(unfollowClass).addClass(followClass);

                    setToastr(toastr, '<span class="text-center">Le véhicule <b>' + $followBtn.attr('data-car-brand') + ' ' + $followBtn.attr('data-car-model') + ' ' + $followBtn.attr('data-car-version') + '</b> a bien été ajouté à vos véhicules suivis. <br> <a href="' + $followBtn.attr('data-path-followed-cars') + '" class="btn btn-blanc btn-block">Voir mes véhicules suivis</a> </span>', 'success');
                }
                else {
                    $followText.html($followBtn.attr('data-unfollow-text'));
                    newFollowersNumber--;

                    $followBtn.removeClass(followClass).addClass(unfollowClass);

                    setToastr(toastr, '<span class="text-center">Le véhicule <b>' + $followBtn.attr('data-car-brand') + ' ' + $followBtn.attr('data-car-model') + ' ' + $followBtn.attr('data-car-version') + '</b> a bien été retiré de vos véhicules suivis. <br> <a href="' + $followBtn.attr('data-path-followed-cars') + '" class="btn btn-blanc btn-block">Voir mes véhicules suivis</a> </span>', 'info');
                }

                if ($followersNumber.length > 0) {
                    $followersNumber.html(newFollowersNumber);
                }

            }

        }).fail(function () {

            setToastr(toastr, 'Oops, votre demande n\'a pu aboutir, veuillez réessayer dans quelques instants', 'error');

        });

    });

    $('.suivreVehicule[data-event-set!=1]').attr('data-event-set', 1);
}

function deleteCarFollowing($deleteCarFollowingButton, toastr)
{

    var followId = $deleteCarFollowingButton.attr('data-id');

    $.ajax({
        url: $deleteCarFollowingButton.attr('data-path'),
        method: 'POST',
        dataType: 'JSON',
        data: {
            followId: followId
        }
    })
    .done(function (data) {

        var toastrMessage;

        if (data) {

            $('#follow' + followId).fadeOut(function () {

                toastrMessage = 'Vous venez d\'annuler le suivi de ce véhicule<br>' +
                '<button class="btn btn-bleuEM cancelDeleteQuotation" data-id="' + followId + '" data-cancel-path="' + $deleteCarFollowingButton.attr('data-cancel-path') + '">Annuler</button>';

                setToastr(toastr, toastrMessage, 'success');

                $('.cancelDeleteQuotation').click(function (event){

                    cancelDeleteCarFollowing($(event.currentTarget), toastr);

                });

            });

        }
        else {

            toastrMessage = 'Oops, votre demande n\'a pu aboutir, veuillez réessayer dans quelques instants';

            setToastr(toastr, toastrMessage, 'error');

        }


    }).fail(function () {

        setToastr(toastr, 'Oops, votre demande n\'a pu aboutir, veuillez réessayer dans quelques instants', 'error');

    });
}

function cancelDeleteCarFollowing($deleteCarFollowingButton, toastr)
{
    var followId = $deleteCarFollowingButton.attr('data-id');

    $.ajax({
        url: $deleteCarFollowingButton.attr('data-cancel-path'),
        method: 'POST',
        dataType: 'JSON',
        data: {
            followId: followId
        }
    })
    .done(function (data) {

        if (data) {

            $('#follow' + followId).fadeIn(function () {

                setToastr(toastr, 'Votre venez d\'annuler la suppression du suivi de ce véhicule', 'info');

            });

        }
        else {

            setToastr(toastr, 'Oops, votre demande n\'a pu aboutir, veuillez réessayer dans quelques instants', 'error');

        }


    }).fail(function () {

        setToastr(toastr, 'Oops, votre demande n\'a pu aboutir, veuillez réessayer dans quelques instants', 'error');

    });
}

$(document).ready(function () {

    setCarFollowing(toastrNode);


    $('.deleteFollowCar').click(function (event){

        deleteCarFollowing($(event.currentTarget), toastrNode);

    });

});
