import $ from 'jquery';
import {SearchPanel} from '../pages/index/search-panel';

$(document).ready(function (){

    $('.moreBrand').click(function (e){
        e.preventDefault();
        $('.bandeauBrand').addClass('active');
    });
    $('.lessBrand').click(function (e){
        e.preventDefault();
        $('.bandeauBrand').removeClass('active');
    });

    if ($('.containSearchMobile').length > 0) {
        let searchPanel = new SearchPanel('.containSearchMobile');
        searchPanel.ready();
    }

    if ($('.containIntro').length) {
        let searchPanelCommercial = new SearchPanel('.containIntro');
        searchPanelCommercial.ready();
    }

    if ($('.searchComm').length) {
        let searchPanelCommercial = new SearchPanel('.searchComm');
        searchPanelCommercial.ready();
    }
});
