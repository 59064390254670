import {EventEmitter} from 'events';

export class IntersectionMonitor extends EventEmitter {

    static get EventInScreen() { return 'in-screen'; }
    static get EventOutsideScreen() { return 'outside-screen'; }
    static get EventUnsupportedScreen() { return 'unsupported-screen'; }

    constructor(selector) {
        super();
        this.selector = selector;
        this.observerIntersect = new IntersectionObserver(elements => this.observerCallBack(elements));
    }

    onReady() {
        const entries = document.querySelectorAll(this.selector);

        entries.forEach(domElement => {
            if (IntersectionMonitor.isSupportIntersectionObserver()) {
                this.observe(domElement);
            } else {
                this.emit(IntersectionMonitor.EventUnsupportedScreen, domElement);
            }
        });
    }

    /**
     * @param {string} selector
     * @param {function} callbackInScreen
     * @param {function} callbackOutsideScreen
     * @param {function} callbackUnsupported
     * @return {IntersectionMonitor}
     */
    static create(selector, callbackInScreen = null, callbackOutsideScreen = null, callbackUnsupported = null) {
        const intersectMonitor = new IntersectionMonitor(selector);
        if (typeof callbackInScreen === 'function') {
            intersectMonitor.on(IntersectionMonitor.EventInScreen, callbackInScreen);
        }
        if (typeof callbackOutsideScreen === 'function') {
            intersectMonitor.on(IntersectionMonitor.EventOutsideScreen, callbackOutsideScreen);
        }
        if (typeof callbackUnsupported === 'function') {
            intersectMonitor.on(IntersectionMonitor.EventUnsupportedScreen, callbackUnsupported);
        }
        intersectMonitor.onReady();
        return intersectMonitor;
    }

    /**
     * @param {HTMLElement} domElement
     */
    observe(domElement) {
        if (this.observerIntersect) {
            this.observerIntersect.observe(domElement);
        }
    }

    /**
     * @param {HTMLElement} domElement
     */
    unobserve(domElement) {
        if (this.observerIntersect) {
            this.observerIntersect.unobserve(domElement);
        }
    }

    /**
     * @param {IntersectionObserverEntry[]} entries
     * @return {void}
     */
    observerCallBack(entries) {
        entries.forEach(intersectionObserverEntry => {
            this.emit(
                intersectionObserverEntry.isIntersecting ? IntersectionMonitor.EventInScreen : IntersectionMonitor.EventOutsideScreen,
                intersectionObserverEntry.target
            );
        });
    }

    /**
     * @returns {boolean}
     */
    static isSupportIntersectionObserver() {
        return 'IntersectionObserver' in window &&
            'IntersectionObserverEntry' in window &&
            'intersectionRatio' in window.IntersectionObserverEntry.prototype;
    }
}
