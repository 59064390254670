import * as jcf from 'jcf/dist/js/jcf';
import $ from 'jquery';
import './form-validator';
import { openModal } from './custom-modal';

function renderHCaptch() {
    const $hcaptchaContainer = $('#quotation-dynamique-modal').find('.h-captcha');
    hcaptcha.render('h-captcha-container', {
        sitekey: $hcaptchaContainer.data('sitekey'),
    });
}

export function registerEvent($modalFormulaireInscription, callBackRegister) {
    // Display hcaptcha
    renderHCaptch();

    $modalFormulaireInscription.find('form').unbind();
    $modalFormulaireInscription.find('form').validator({ focus: false });

    $modalFormulaireInscription.validator({ focus: false }).on('submit', function (e) {
        var $formulaire = $modalFormulaireInscription.find('form');
        var path = $formulaire.attr('action');
        var lastName = $formulaire.find('input[name="register_customer[person][lastName]"]').val();
        var firstName = $formulaire.find('input[name="register_customer[person][firstName]"]').val();
        var apeCode = $formulaire.find('input[name="register_customer[apeCode]"]').val();
        var phone = $formulaire.find('input[name="register_customer[phone]"]').val();
        var email = $formulaire.find('input[name="register_customer[user][email]"]').val();
        var isProfessionnelInscription = $formulaire.find('input[name="isProfessionnelInscription"]').val();
        var acceptNewsLetter = $formulaire.find('input[name="acceptNewsLetter"]').is(':checked');
        var customertargetPath = $formulaire.find('input[name="register_customer[_target_path]"]').val();
        var targetPath = $formulaire.find('input[name="_target_path"]').val();
        var token = $formulaire.find('input[name="register_customer[_token]"]').val();
        var termeAccepted = $formulaire.find('input[name="register_customer[user][termsAccepted]"]').val();
        var bloctel = $formulaire.find('input[name="register_customer[user][bloctel]"]').val();
        var civility = $formulaire.find('select[name="register_customer[civility]"]').val();
        var isLightMode = $formulaire.find('input[name="light"]').val();
        var legalForm = $formulaire.find('select[name="register_customer[legalForm]"]').val();
        const gRecaptchaResponse = $formulaire.find('textarea[name="g-recaptcha-response"]').val();
        const hRecaptchaResponse = $formulaire.find('textarea[name="h-captcha-response"]').val();

        var registerCustomer = {
            person: {
                lastName,
                firstName,
            },
            phone,
            apeCode,
            user: {
                email,
                termsAccepted: termeAccepted,
                bloctel,
            },
            civility,
            legalForm,
            _target_path: customertargetPath,
            _token: token,
        };

        if (e.isDefaultPrevented() === false) {
            $('.loaderPDF#loaderAjaxRegister').removeClass('hide');
            $('.inscriptionAjax').addClass('hide');
            $.ajax({
                url: path,
                method: 'POST',
                data: {
                    register_customer: registerCustomer,
                    _target_path: targetPath,
                    isProfessionnelInscription,
                    acceptNewsLetter: Number(acceptNewsLetter),
                    isLightMode,
                    isAjax: true,
                    'g-recaptcha-response': gRecaptchaResponse,
                    'h-captcha-response': hRecaptchaResponse,
                },
                dataType: 'json',
                success: function (rep) {
                    if (typeof rep.statut !== 'undefined') {
                        if (rep.statut === true) {
                            reloadMenu();
                            callBackRegister();
                        } else {
                            $modalFormulaireInscription.html(rep.html);
                            $('.apeCode').removeAttr('minlength').prop('required', false).attr('data-validate', false)
                                .validator('update')
                                .parent()
                                .hide();
                            $('.isProfessionnelInscription:checked').each(function (index, element) {
                                updateSignupFormReseller($(element));
                            });
                            jcf.replaceAll();
                            $('[rel="tooltip"]').tooltip();

                            // Display hcaptcha
                            renderHCaptch();
                        }
                    }
                },
            });
            e.preventDefault();
            return false;
        }
    });
}

// ReloadMenu
function reloadMenu() {
    var $header = $('header');
    var path = $header.find('.container').attr('data-path');

    $.ajax({
        url: path,
        method: 'POST',
        dataType: 'json',
    }).done(function (rep) {
        if (typeof rep.statut !== 'undefined' && rep.statut === true) {
            $header.html(rep.html);
        }
    });
}

function updateSignupFormReseller($isPro) {
    var $firstName = $('.firstName');
    var $civility = $('.jcf-select-civility');
    var $legalForm = $('.jcf-select-legal-form');
    var $apeCode = $('.apeCode');

    if ($isPro.is(':checked')) {
        $isPro.val(1);
        $civility.parent().addClass('hide');
        $civility.children('option:first-child').attr('selected', true);
        $legalForm.parent().removeClass('hide');
        $apeCode.prop('required', true).attr('data-validate', true).validator('update').parent().show();

        $firstName.val('').removeAttr('minlength').prop('required', false).attr('data-validate', false).validator('update').parent().hide();
        jcf.refresh($civility);
    } else {
        $isPro.val(0);
        $legalForm.parent().addClass('hide');
        $legalForm.children('option:first-child').attr('selected', true);
        $civility.parent().removeClass('hide');
        $apeCode.prop('required', false).attr('data-validate', false).validator('update').parent().hide();

        $firstName.attr('minlength', 3).prop('required', true).attr('data-validate', true).validator('update').parent().show();

        jcf.refresh($legalForm);
    }

    $('.signup-form').validator('validate');
}

function updateSignupFormCivility($civility) {
    var $firstName = $('.firstName');

    switch (parseInt($civility.val(), 10)) {
    case 4:
        $firstName.val('').removeAttr('minlength').prop('required', false).attr('data-validate', false).validator('update').parent().hide();
        break;

    default:
        $firstName.attr('minlength', 3).prop('required', true).attr('data-validate', true).validator('update').parent().show();
        break;
    }

    $('.signup-form').validator('validate');
}

$(document).ready(function () {
    var $apeCode = $('.apeCode');
    $apeCode.removeAttr('minlength').prop('required', false).attr('data-validate', false).validator('update').parent().hide();

    $apeCode.keyup(function (event){
        var $this = $(event.currentTarget);
        var reg= new RegExp('[0-9]{2}', 'g');

        if ($this.val().length === 2 && $this.val().match(reg)){
            $this.val($this.val() + '.');
        }
    });

    $('.callInscriptionMenuCompte').click(function () {
        $('.formCompte .initialShown').slideUp(function () {
            $('.formCompte .initialHidden').slideDown();
        });
    });

    $('.callConnexionMenuCompte').click(function () {
        $('.formCompte .initialHidden').slideUp(function () {
            $('.formCompte .initialShown').slideDown();
        });
    });

    $('body :not(header, header *)').click(function () {
        // On appelle la fonction de display du menu
        if ($('.menuCompte').hasClass('in')) {
            $('.btnMenuCompte').collapse({
                toggle: false,
            });
        }
    });

    $('header nav ul:not(.listeMenuCompte)').hover(function () {
        // On appelle la fonction de display du menu
        if ((window.matchMedia('(min-width: 1300px)').matches) && $('.menuCompte').hasClass('in')) {
            $('.btnMenuCompte').collapse('hide');
        }
    });

    $('.isProfessionnelInscription:checked').each(function (index, element) {
        updateSignupFormReseller($(element));
    });

    $(document).on('click', '.isProfessionnelInscription', function (event) {
        updateSignupFormReseller($(event.currentTarget));
    });

    $('.civility option[value="4"]:selected').each(function (index, element) {
        updateSignupFormCivility($(element));
    });

    $('.civility').change(function (event) {
        updateSignupFormCivility($(event.currentTarget));
    });

    $(document).on('click', '#connexionAjax', function (event) {
        var $btn = $(event.currentTarget);
        var $form = $btn.parent().parent();
        var username = $form.find('[name="_username"]').val();
        var password = $form.find('[name="_password"]').val();
        var targetPath = $form.find('[name="_target_path"]').val();
        var failurePath = $form.find('[name="_failure_path"]').val();
        var csrfToken = $form.find('[name="_csrf_token"]').val();
        var $path = $form.attr('action');

        $btn.hide();
        $('.loaderPDF#loaderLoginAjax').removeClass('hide');

        event.preventDefault();

        $.ajax({
            url: $path,
            method: 'POST',
            data: {
                _username: username,
                _password: password,
                _target_path: targetPath,
                _failure_path: failurePath,
                _csrf_token: csrfToken,
            },
            dataType: 'json',
            success: (function (rep) {
                if ('isCommand' in rep && rep.isCommand === true) {
                    window.location.href = rep.redirectcommand;
                } else {
                    $btn.show();
                    $('.loaderPDF#loaderLoginAjax').addClass('hide');
                    if (typeof rep === 'object') {
                        if (typeof rep.statut !== 'undefined' && rep.statut === false) {
                            $('#modalFormulaireConnexion').html(rep.html);
                        } else {
                            $('#modalTunnelDevisDynamique').modal('hide');
                            reloadMenu();
                            openModal('', rep.html, '', 'document');
                        }
                    }
                }
            }),
        });
    });
});
