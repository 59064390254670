import {EventEmitter} from 'events';

export class SearchModule extends EventEmitter
{
    constructor(selector) {
        super();
        this.selector = selector;
        this.classContainerResult = '';
        this.latency = 200;
        this.lastQuery = '';
        this.classActive = 'active';
        this.isOpenResult = false;
        this.startScrollItems = 4;
        this._lastResultApi = [];
        this._itemSelected = null;
        this._isLoading = false;
    }

    ready() {
        /** @type HTMLInputElement inputElement */
        this.inputElement = document.querySelector(this.selector);

        if (this.inputElement === null) {
            return false;
        }

        /** @type string */
        this.url = this.inputElement.dataset.url;

        if (!this.url) {
            throw new Error('url is missing for search module');
        }
        this.events();
        return true;
    }

    /**
     * Define global events on input search
     */
    events() {
        this.inputElement.addEventListener('keyup', e => this.filterResult(e));
        this.inputElement.addEventListener('keydown', e => this.navigationList(e));
        this.inputElement.addEventListener('focusout', e => this.focusChange(e));
        this.inputElement.addEventListener('focusin', e => this.focusChange(e));
    }

    /**
     * Define event on item list
     */
    eventsResult() {
        this.itemsDOM.forEach(element => {
            element.addEventListener('click', () => {
                this.changeValueInput(element.dataset.index);
            });
            let touchMoved;
            element.addEventListener('touchstart', () => {
                touchMoved = false;
            });
            element.addEventListener('touchmove', () => {
                touchMoved = true;
            });
            element.addEventListener('touchend', ev => {
                ev.preventDefault();
                if (!touchMoved){
                    this.changeValueInput(element.dataset.index);
                }
            });
        });
    }

    /**
     * Navigation in result with keyboard
     * @param {KeyboardEvent} e
     * @return {null}
     */
    filterResult(e) {

        if (e.code === 'ArrowDown' || e.code === 'ArrowUp' || e.code === 'Enter') {
            return null;
        }

        this._isLoading = true;
        let urlSearch = new URLSearchParams();
        let query = this.inputElement.value;
        urlSearch.append('query', query);

        this.removeLastResult();
        if (!query) {
            this.changeValueInput(-1);
            this._isLoading = false;
            return;
        }

        if (this.timeoutLatency) {
            clearTimeout(this.timeoutLatency);
        }

        this.timeoutLatency = setTimeout(() => {
            this.emit('loading');
            fetch(this.url + '?' + urlSearch.toString()).then(value => {
                this._isLoading = false;
                this.emit('loaded');
                this.lastQuery = query;

                if (this.lastQuery !== this.inputElement.value) {
                    this.filterResult();
                }

                value.json().then(searchModels => {
                    this._lastResultApi = searchModels;
                    this.render(searchModels, query);
                }).catch(() => {
                    this._lastResultApi = [];
                });
            }).catch(() => {
                this._lastResultApi = [];
            });
        }, this.latency);
    }

    /**
     * Render result
     * @param searchModels
     * @param query
     */
    render(searchModels, query) {
        this.removeLastResultDom();

        let buttons = [];
        for (let searchModelIndex of Object.keys(searchModels)) {
            let label = searchModels[searchModelIndex].label;
            label = label.replace(new RegExp('(' + query.split(' ').join('|') + ')', 'gi'), '<mark>$&</mark>');
            buttons.push(`<button class="list-group-item" data-index="${searchModelIndex}" type="button" style="cursor: pointer">${label}</button>`);
        }

        let dom = `
            <div data-role="search-result" ${this.classContainerResult ? `class=${this.classContainerResult}` : ''}
                style="width: ${this.inputElement.offsetWidth}px; position: absolute; top:${this.inputElement.offsetHeight}px; left: 0;">
                <div class="list-group">
                    ${buttons.join('\n')}
                </div>
            </div>
        `;
        this.inputElement.insertAdjacentHTML('afterend', dom);
        this.eventsResult();
        this.showResult();
    }

    /**
     * Remove list item in dom
     */
    removeLastResultDom() {
        let resultDom = this.resultDOM;

        if (resultDom && resultDom.dataset && resultDom.dataset.role === 'search-result') {
            resultDom.remove();
        }
    }

    /**
     * Remove list item in dom
     */
    removeLastResult() {
        this._itemSelected = null;
        this._lastResultApi = [];
    }

    /**
     * Change value of input
     * @param index
     */
    changeValueInput(index) {
        index = Number(index);
        this._itemSelected = this._lastResultApi[index];
        this.addActiveItem(index);
        if (this._itemSelected) {
            this.inputElement.value = this._itemSelected.label;
        } else {
            this.inputElement.value = '';
        }
        this.emit('change', this._itemSelected);
        setTimeout(() => {
            this.hideResult();
        }, 20);
    }

    /**
     * Navigation in result with keyboard
     * @param e
     * @type KeyboardEvent
     * @return {null}
     */
    navigationList(e)
    {
        let currentFocus = -1;
        if (!this.resultDOM) {
            return null;
        }

        const elementSearch = this.resultDOM,
            listItem = this.itemsDOM,
            item = listItem[0];

        if (this.activeItemDOM) {
            currentFocus = this.getIndexItem(this.activeItemDOM);
        }

        if (e.code === 'ArrowDown') {
            currentFocus++;
            this.addActiveItem(currentFocus);
            if (currentFocus >= listItem.length){
                elementSearch.scrollTop = 0;
                this.addActiveItem(0);
            } else if (currentFocus >= this.startScrollItems){
                elementSearch.scrollTop += item.clientHeight;
            }
        } else if (e.code === 'ArrowUp') {
            currentFocus--;
            if (currentFocus < 0){
                elementSearch.scrollTop = elementSearch.scrollHeight -elementSearch.clientHeight;
                this.addActiveItem(listItem.length-1);
            } else {
                this.addActiveItem(currentFocus);
                elementSearch.scrollTop -= item.clientHeight;
            }
        } else if (e.code === 'Enter') {
            if (this.activeItemDOM && this.isOpenResult) {
                e.preventDefault();
                this.changeValueInput(this.activeItemDOM.dataset.index);
            }
        }

    }

    /**
     * Add active class on the item defined by the index
     * @param index
     */
    addActiveItem(index) {
        index = index + 1;
        this.removeActiveItem();
        if (!this.resultDOM) {
            return;
        }
        let newActiveItem = this.resultDOM.querySelector('.list-group-item:nth-child(' + index + ')');
        if (newActiveItem) {
            newActiveItem.classList.add(this.classActive);
        }
    }

    /**
     * Remove class active of active item
     */
    removeActiveItem() {
        if (this.activeItemDOM) {
            this.activeItemDOM.classList.remove(this.classActive);
        }
    }

    /**
     * @param e
     * @type FocusEvent
     */
    focusChange(e) {
        if (e.type === 'focusin') {
            this.showResult();
        } else {

            let relatedTarget = e.relatedTarget;
            if (relatedTarget === null) {
                relatedTarget = this.activeItemDOM;
            }
            if (relatedTarget === null) {
                relatedTarget = this.focusItemDOM;
            }

            if (this.getIndexItem(relatedTarget) === -1) { // check click on list result
                this.hideResult();
            }
        }
    }

    /**
     * Show result list items
     */
    showResult() {
        if (this.resultDOM) {
            this.resultDOM.style.display = null;
        }
        this.isOpenResult = true;
    }

    /**
     * Hide result list items
     */
    hideResult() {
        if (this.resultDOM) {
            this.resultDOM.style.display = 'none';
        }
        this.isOpenResult = false;
    }

    /**
     * @return {HTMLElement}
     */
    get resultDOM() {
        return document.querySelector(this.selector + ' + [data-role="search-result"]');
    }

    /**
     * @return {HTMLElement}
     */
    get activeItemDOM() {
        return this.resultDOM ? this.resultDOM.querySelector('.' + this.classActive) : null;
    }

    /**
     * @return {HTMLElement}
     */
    get focusItemDOM() {
        return this.resultDOM ? this.resultDOM.querySelector('.list-group-item:hover') : null;
    }

    /**
     *
     * @return {HTMLElement[]}
     */
    get itemsDOM() {
        return this.resultDOM ? this.resultDOM.querySelectorAll('.list-group-item') : [];
    }

    get resultApi() {
        return this._lastResultApi;
    }

    /**
     * Return item selected
     * @return {object}
     */
    get itemSelected() {
        return this._itemSelected;
    }

    get isLoading() {
        return this._isLoading;
    }

    getIndexItem(item) {
        return [].slice.call(this.itemsDOM).indexOf(item);
    }
}

