import $ from 'jquery';
import * as jcf from 'jcf/dist/js/jcf';
import 'jcf/dist/js/jcf.select';
import 'jcf/dist/js/jcf.scrollable';
import 'jcf/dist/js/jcf.radio';
import 'jcf/dist/js/jcf.checkbox';

$(document).ready(function () {

    jcf.setOptions('Select', {
        wrapNative: false,
        // wrapNativeOnMobile: false,
        fakeDropInBody: false
    });

    jcf.replaceAll();

    $('.colorSwatch .jcf-checkbox').hide();

    jcf.destroy($('.containTabsFaq input[type="radio"]'));
    jcf.destroy($('input.noJcf'));
    jcf.destroy($('input[name^="cookie_consent"]'));
    jcf.destroy($('input.inputSwitch'));
    jcf.destroy($('.select2'));

});
