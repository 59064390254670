import $ from "jquery";

$(document).ready(function () {

    $('#readMoreDesc').click(function (){
        $('#descriptionType').toggleClass('active');
        $('#readMoreDesc i').toggleClass('icon-arrow-down icon-arrow-up');
        $('#readMoreDesc .more').toggle();
        $('#readMoreDesc .less').toggle();
    });
});

