import $ from 'jquery';
import './modernizr-custom';

var currentScrollPosition = window.scrollY;
var scrollTimer = null;

function handleScroll(start) {

    var fab, scrollTop, fromBottom, bottomOffset, fabOffset, scrollDownMax;

    if (start === 1 ) {
        hideScrollTop(1);
    }
    else {
        fab = $('#fab');
        scrollTop = $('#scrolltop');
        scrollDownMax = 200;
        bottomOffset = 0; /* handle div fixed bottom => bottomOffset */
        fabOffset = fab.outerHeight();

        fromBottom = $('.subFooter');
        if ($('.subFooter1299')[0]){
            fromBottom = $('.subFooter1299');
        }
        if (fromBottom[0]){
            bottomOffset = fromBottom.outerHeight();
        }
        if ((Modernizr.mq('(min-width: 1299px)')) || (window.matchMedia('(min-width: 1299px)').matches)) {
            bottomOffset = 0;
            fabOffset = 0;
        }

        // going down
        if (window.scrollY > currentScrollPosition) {

            if (window.scrollY > scrollDownMax){
                scrollTop.css('bottom', bottomOffset + fabOffset + 14 + 'px');
            }
            else {
                hideScrollTop(1);
            }
        }

        // going up
        else {
            fab.css('bottom', bottomOffset + 7 + 'px');
            if(window.scrollY == 0){ // si arrivé en haut
                hideScrollTop(1);
            }
            else {
                scrollTop.css('bottom', bottomOffset + fabOffset + 14 + 'px');
            }
        }

        // tel tout le temps affiché sur petit ecran
        fab.css('bottom', bottomOffset + 7 + 'px');

        currentScrollPosition = window.scrollY;
    }
}

$(document).scroll(function () {
    if (scrollTimer) {
        clearTimeout(scrollTimer);   // clear any previous pending timer
    }
    scrollTimer = setTimeout(handleScroll, 100);   // set new timer
});

$(document).ready(function () {
    handleScroll(1);
});
$(window).resize(function() {
    handleScroll();
});

$('#scrolltop').click(function () {

    if (window.scrollY > 0) {

        $('body,html').animate({
            scrollTop: 0
        }, 800);

    }
});

function hideScrollTop(bottom){
    if (window.scrollY > 100){
        $('#scrolltop').css('bottom', bottom+'em');

    }
    else {
        $('#scrolltop').css('bottom', '-6em');
    }
}

