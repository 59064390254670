import $ from 'jquery';

$(document).ready(function () {
    $('#modalRappel').on('show.bs.modal', function (ev) {
        fetch(ev.target.dataset.content)
            .then(r => {
                if (r.ok) {
                    return r.text();
                }
                throw new Error();
            })
            .then(c => {
                $('#modalRappel').find('.modal-body').html(c);
            });
    });
    $('.callDemandeDeRappel').click(function () {
        $('#modalRappel').modal('show');
    });

    $(document).on('submit', 'form[name="callback"]', function (ev) {
        ev.preventDefault();

        runHcaptcha($(ev.target))
            .then(response => {
                $(ev.target).find('button[type="submit"]').prop('disabled', true);
                const formData = new FormData(ev.target);
                if (response !== null) {
                    formData.set('h-captcha-response', response);
                }
                fetch(ev.target.action, { method: 'POST', body: formData })
                    .then(r => {
                        if (r.ok) {
                            return r.text();
                        }
                        throw new Error();
                    })
                    .then(c => {
                        $(ev.target).replaceWith(c);
                    });
            });
    });
});

function runHcaptcha(container) {
    return new Promise((resolve, reject) => {
        const hcaptchaEl = container.find('.hCaptcha');
        if (hcaptchaEl.length === 0) {
            resolve(null);
            return;
        }
        $('#hcaptcha-callback').html('');
        const widgetId = hcaptcha.render('hcaptcha-callback', {
            sitekey: hcaptchaEl.data('sitekey'),
            size: 'invisible',
        });
        hcaptcha.execute(widgetId, { async: true })
            .then(({ response }) => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });
}
