import $ from 'jquery';
import {setToastr} from './toastr';
import {getCookie} from './cookieHelper';
import toastrNode from 'toastr/build/toastr.min';

const ERROR_MSG_COMPARATOR = 'Oops, votre demande n\'a pu aboutir, veuillez réessayer dans quelques instants';

export class ComparatorModule {

    constructor() {

        if (!ComparatorModule.instance) {
            this.comparatorId = [];
            this.comparatorCookie = [];
            this.carId = [];
            this.index = [];
            this.nbCarsInComparator = 0;
            ComparatorModule.instance = this;
        } else {
            return ComparatorModule.instance;
        }
    }

    ready() {
        this.comparatorCookie = this.cookieComparator.length !== 0 ? JSON.parse(this.cookieComparator) : [];

        this.setAddToComparator(toastrNode);
        this.$comparatorTabClass.addClass('comparateurOpen');

        if (this.comparatorCookie.length !== 0){
            this.alreadyComparedButtonsSetter(this.comparatorCookie);
        }

        this.events();
    }

    events() {
        $(document).on('click', '.comparateurOpen', () => this.comparatorOpening());
        $(document).on('click', '.vehiculeComparateur .deleteCard, #tabComparateur .deleteCard', e => this.removeCarFromComparator($(e.currentTarget)));
    }

    comparatorOpening()
    {
        this.$comparatorTabClass.removeClass('comparateurOpen');
        this.openComparatorLoader(toastrNode);
    }

    /**
     *
     * @param toastr
     */
    openComparatorLoader(toastr)
    {
        this.$comparatorContainer.hide();
        this.$addCarToComparator.hide();
        this.$compareLoaderClass.fadeIn();

        $.ajax({
            url: `/call-comparator.json`,
            type: 'POST',
            dataType: 'json'
        }).done( response => {

            this.comparatorCookie = this.cookieComparator.length !== 0 ? JSON.parse(this.cookieComparator) : [];
            if (response.comparedCarsCount < this.comparatorCookie.length)
            {
                this.openComparatorLoader(toastr);
            }

            this.$compareLoaderClass.fadeOut( () => {
                this.$comparatorTab.replaceWith(response.comparatorView);
            });

            setTimeout(() => {
                this.$collapseComparator.collapse('show');
            }, 500);


        }).fail(() => {
            this.$compareLoaderClass.fadeOut();
            setToastr(toastr, ERROR_MSG_COMPARATOR, 'error');
        });
    }


    /**
     *
     * @param toastr
     */
    setAddToComparator(toastr) {

        this.$compareCarClassEvent.on('click', event => {

            this.carId = $(event.currentTarget).attr('data-car-id');

            this.$compareCarClass.hide();
            this.$compareLoadingClass.removeClass('hide');
            this.$compareLoadingClass.css('background', 'orange');

            if (this.cookieComparator.length > 0) {

                this.comparatorCookie = JSON.parse(this.cookieComparator);

                if ($.inArray(this.carId, this.comparatorCookie) >= 0) {
                    setToastr(toastr, 'Ce véhicule est déjà dans votre comparateur', 'error');
                    this.$compareLoadingClass.addClass('hide');
                    this.$compareCarClass.show();
                    return false;
                }

                if (this.comparatorCookie.length >= 3) {
                    setToastr(toastr, 'Vous avez déjà 3 véhicules dans votre comparateur', 'error');
                    this.$compareLoadingClass.addClass('hide');
                    this.$compareCarClass.show();
                    return false;
                }
            }

            setToastr(toastr, 'Ajout en cours de ce véhicule à votre comparateur, veuillez patienter...');

            if (this.cookieIdComparator.length !== 0) {
                this.addCarToComparator(this.cookieIdComparator, this.carId, this.comparatorCookie, toastr);
            }
            else {
                // On a pas de comparaison en cours, on en créer une nouvelle en base de données
                this.newDBCreator(toastr, this.carId, this.comparatorCookie, ERROR_MSG_COMPARATOR);
            }

        });

        this.$compareCarClassEvent.attr('data-event-set', 1);
    }

    /**
     *
     * @param comparatorId
     * @param carId
     * @param comparatorCookie
     * @param toastr
     */
    addCarToComparator(comparatorId, carId, comparatorCookie, toastr) {

        this.$comparatorTab.removeClass('hide');
        this.$compareLoaderClass.fadeIn();

        // On enregistre en bdd la comparaison (pour les stats uniquement) et on retourne la vue
        // Si l'ajax renvoi une erreur on stock quand même la comparaison en cookie pour que le client ai sa comparaison en rechargeant la page
        $.ajax({
            url: this.$comparatorTab.attr('data-add-to-comparator-path'),
            method: 'POST',
            dataType: 'JSON',
            data: {
                carId: carId,
                comparatorId: comparatorId
            }
        }).done(data => {
            this.$compareLoaderClass.fadeOut();
            // On ajoute juste le véhicule aux véhicules comparés
            if (data.comparatorCarHTML.length > 0) {

                this.$comparatorContainer.append(data.comparatorCarHTML);
                this.nbCarsInComparator = this.$carComparator.length;
                this.$countComparator.html('('+this.nbCarsInComparator+')');

                if (this.nbCarsInComparator === 1)
                {
                    this.$countComparator.css('display', '');
                    this.$countComparator.removeClass('hide');
                }

                // On affiche
                this.$addCarToComparator.addClass('hide');
                this.$openModalComparator.removeClass('hide');
                this.$addCarThisToComparator.hide();


                if (this.nbCarsInComparator < 3) {
                    // On a moins de 3 voitures dans le comparateur, on le masque automatiquement
                    window.setTimeout(() => {
                        this.$collapseComparator.collapse('hide');
                    }, 1750);
                }

                this.setComparatorCookie(comparatorId, 'comparator-id');

                comparatorCookie.push(carId);

                this.setComparatorCookie(JSON.stringify(comparatorCookie), 'cars-comparator');
            }

            this.$modalComparator.html(data.comparatorModalHTML);

            setToastr(toastr, 'Ce véhicule est ajouté à votre comparateur', 'success');


            setTimeout(() => {
                this.$compareLoadingClass.addClass('hide');
                this.comparedCarId = carId;
                this.comparedCarId.addClass('followingButton');
                this.$compareCarClass.show();
                this.$collapseComparator.collapse('show');
            }, 1500);


        }).fail(() => {
            this.$compareLoaderClass.fadeOut();
            setToastr(toastr, ERROR_MSG_COMPARATOR, 'error');
            this.$compareLoadingClass.addClass('hide');
            this.$compareCarClass.show();
        });
    }

    /**
     *
     * @param toastr
     * @param carId
     * @param comparatorCookie
     * @param errorMessage
     */
    newDBCreator(toastr, carId, comparatorCookie, errorMessage)
    {
        $.ajax({
            url: this.$comparatorTab.attr('data-create-comparator-path'),
            method: 'POST',
            dataType: 'JSON'
        })
        .done(data => {

            this.comparedCarId = carId;

            setTimeout(() => {
                this.$compareLoadingClass.addClass('hide');

                this.comparedCarId.addClass('followingButton');
                this.$compareCarClass.show();
            }, 1500);

            if (data.error) {
                setToastr(toastr, errorMessage, 'error');
            }
            else {
                this.addCarToComparator(data.comparatorId, carId, comparatorCookie, toastr);
            }

        })
        .fail(() => {
            setToastr(toastr, errorMessage, 'error');
            this.$compareLoadingClass.addClass('hide');
            this.$compareCarClass.show();
        });
    }

    /**
     *
     * @param ids
     */
    alreadyComparedButtonsSetter(ids)
    {
        for (let i = 0; i < ids.length; i++)
        {
            this.comparedCarId = ids[i];
            this.comparedCarId.addClass('followingButton');
        }
    }

    /**
     *
     * @param $card
     */
    removeCarFromComparator($card) {

        this.comparatorCookie = JSON.parse(this.cookieComparator);
        this.carId = $card.attr('data-id');
        this.index = this.comparatorCookie.indexOf(this.carId);
        this.comparatorCarIdClass = this.carId;
        this.comparatorCookie.splice(this.index, 1);

        // On supprime le cookie
        this.setComparatorCookie(JSON.stringify(this.comparatorCookie), 'cars-comparator');
        // On supprime la carte
        this.comparatorCarIdClass.hide('slow', () => {

            this.comparatorCarIdClass.remove();

            // Si on a plus de véhicules dans le comparateur, on supprime le cookie comparator-id
            this.nbCarsInComparator = this.$carComparator.length;

            if (this.nbCarsInComparator === 0) {

                this.$openModalComparator.addClass('hide');
                this.$addCarToComparator.removeClass('hide');
                this.$countComparator.hide();

                this.setComparatorCookie('', 'comparator-id');
                this.$modalComparator.modal('hide');
            }

            this.$countComparator.html('(' + this.nbCarsInComparator + ')');
            this.comparedCarId = this.carId;
            this.comparedCarId.removeClass('followingButton');
        });
    }

    /**
     *
     * @param cookieValue
     * @param cookieName
     */
    setComparatorCookie(cookieValue, cookieName) {

        let d = new Date();
        d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));

        document.cookie = cookieName + '=' + cookieValue + '; expires=' + d.toUTCString() + '; path=/';
    }


    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get comparedCarId() {
        return this.$comparedCarId;
    }

    /**
     *
     * @param carId
     */
    set comparedCarId(carId) {
        this.$comparedCarId = $('#comparerVehicule' + carId);
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get comparatorCarIdClass() {
        return this.$comparatorCarId;
    }

    /**
     *
     * @param carId
     */
    set comparatorCarIdClass(carId) {
        this.$comparatorCarId = $('.comparateur' + carId);
    }

    /**
     *
     * @returns {*|string}
     */
    get cookieComparator() {
        return getCookie('cars-comparator');
    }

    /**
     *
     * @returns {*|string}
     */
    get cookieIdComparator() {
        return getCookie('comparator-id');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $carComparator()
    {
        return $('.vehiculeComparateur');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $compareCarClass()
    {
        return $('.comparerVehicule');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $compareCarClassEvent()
    {
        return $('.comparerVehicule[data-event-set!=1]');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $compareLoadingClass()
    {
        return $('.carComparedLoading');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $compareLoaderClass()
    {
        return $('.carComparedLoader');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $comparatorTabClass()
    {
        return $('.comparateurOnglet');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $comparatorTab()
    {
        return $('#containComparateurOnglet');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $countComparator()
    {
        return $('#comparateurCompteur');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $comparatorContainer()
    {
        return $('#comparatorContainer');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $addCarToComparator()
    {
        return $('#addCarToComparator');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $addCarThisToComparator()
    {
        return $('#addCarThisToComparator');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $openModalComparator()
    {
        return $('#openModalComparator');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $collapseComparator()
    {
        return $('#collapseComparateur');
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    get $modalComparator()
    {
        return $('#modalComparateur');
    }

}
