import $ from 'jquery';

$(document).ready(function () {

    // $(document).on('submit', '.menuCompte .signup-form', function (e) {
    //     e.preventDefault();
    //
    //     var $form = $(e.currentTarget);
    //     $.ajax({
    //         url: $form.attr('action'),
    //         method: $form.attr('method'),
    //         data: $form.serialize(),
    //         success: function (data) {
    //             $form.replaceWith(data);
    //         }
    //     });
    // });

});
