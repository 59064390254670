import $ from 'jquery';

$(document).ready( function () {
    executeValidator();
});

export function executeValidator(){
    // FIXME Gowsan si la validation ne marche pas changer la ligne 378 de /home/web6/Projects/front-v2/bower_components/bootstrap-validator/dist/validator.js par $(document).ready( function () {
    var $input = $('form[data-toggle="validator"] input');

    $('form[data-toggle="validator"] input[type="email"]' ).attr('pattern', '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}');

    $input.filter('.cellphone').attr('pattern', '^((\\+336|\\+337|06|07)([0-9]{8}|[0-9\\s]{12})$|(00)([0-9]{11,12}|[0-9\\s]{15,16})$|\\+([0-9]{11,12}|[0-9]{15,16})$)');

    $input.filter('.phone').attr('pattern', '^((\\+33[^6-7]|0[^6-7])([0-9]{8}|[0-9\\s]{12})$|(00)([0-9]{11,12}|[0-9\\s]{15,16})$|\\+([0-9]{11,12}|[0-9]{15,16})$)');

    $input.filter('.allTypeOfPhone').attr('pattern', '^(00[0-9]{2}|0|\\+[0-9]{2})[0-9]{9}$');

    $input.filter('.word').attr('pattern', '^[^0-9]+$');

    $('.validator-error').removeClass('validator-error').addClass('has-error has-danger');
}
