import $ from 'jquery';
import {toggleMenu} from './header';

function toggleSearchMobile() {
    $('#filtrerResultats').collapse('hide');

    var $containSearchMobile = $('.containSearchMobile');
    var $headerMobile = $('header.mobile');

    if ($containSearchMobile.hasClass('is-active')) {
        $containSearchMobile.removeClass('is-active');
        $containSearchMobile.css('top', ($headerMobile.outerHeight() - $containSearchMobile.outerHeight()) + 'px');
        setTimeout(function () {
            $('body > header.mobile').css('border-bottom-width', '3px');
        }, 425);
        $('#coverMenu').fadeOut('fast');
        $('.tcon').removeClass('tcon-transform');
    }
    else {
        if ($('header.mobile nav').hasClass('is-active')) {
            toggleMenu();
        }
        $('#menuInAside').collapse('hide');
        $containSearchMobile.show().css('top', ($headerMobile.outerHeight() - $containSearchMobile.outerHeight()) + 'px');
        $containSearchMobile.addClass('is-active');
        $containSearchMobile.css('top', ($headerMobile.outerHeight() - 3) + 'px');
        $('body > header.mobile').css('border-bottom-width', '0');
        $('#coverMenu').fadeIn('slow');
    }

}

$(document).ready(function () {
    $('.containSearchMobile').css('top', '-50%');
});

$('#headerSearch').click(function () {
    toggleSearchMobile();
});
