// import './../../other_components/jcf/js/jcf';
// import './../../other_components/jcf/js/jcf.checkbox';
// import './../../other_components/jcf/js/jcf.radio';
// import './../../other_components/jcf/js/jcf.scrollable';
import '@zeitiger/elevatezoom/jquery.elevateZoom-3.0.8.min';
import 'bootstrap-fileinput';
import 'bootstrap-sass';
import 'bootstrap-tour/build/js/bootstrap-tour';
import 'bootstrap-tour/build/js/bootstrap-tour-standalone';
import 'bootstrap-validator/dist/validator';
import 'cookies-js/dist/cookies';
import 'html2canvas';
import 'jquery';
import 'matchmedia';
import 'outdated-browser/outdatedbrowser/outdatedbrowser';
import 'picturefill';
import './modules/video';
// import './modules/allow-cookies';
import './modules/bootstrap-validator-included';
import './modules/callback';
// import './modules/centerModals';
import './modules/comparator';
import './modules/cookie-manager';
import './modules/datePicker';
import './modules/delete-cookies';
import './modules/fab';
import './modules/facebookEvents.js';
import './modules/forget-password';
import './modules/functions';
import './modules/header';
import './modules/ie-adapt';
import './modules/input-mask';
import './modules/input-slider';
import './modules/jcf';
import './modules/login-signup';
import './modules/modernizr-custom';
import './modules/mon-compte-log';
import './modules/outdated-browser';
import './modules/search';
import './modules/search-mobile';
import './modules/car-readMore';
import './modules/suivreVehicule';
import {AgencyModule} from './modules/agency';
import './modules/toastr';
import LazyLoadPicture from './modules/lazy-load-picture';
// import './../js/modules/cookie-consent';
LazyLoadPicture.create();
AgencyModule.create('.select-agency');


