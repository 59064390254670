import DocumentLoaded from './document-loaded';

let agencyModule = null;
export class AgencyModule extends DocumentLoaded {
    constructor(selector) {
        super();
        if (agencyModule) {
            return agencyModule;
        }
        this.selector = selector;

        agencyModule = this;
    }

    onReady() {
        this.init();
    }

    static create(selector) {
        return new AgencyModule(selector);
    }

    init() {
        /** @property {HTMLSelectElement} selectDom */
        this.selectDom = document.querySelectorAll(this.selector);
        this.events();
    }

    events() {
        this.selectDom.forEach(value => {
            value.addEventListener('change', () => this.onChange(value));
        });
    }

    /**
     *
     * @param {HTMLSelectElement} element
     */
    onChange(element) {
        this.changeAgency(element.value);
    }

    /**
     *
     * @param {string} businessUnitCode
     */
    changeAgency(businessUnitCode) {
        const formData = new URLSearchParams();
        formData.append('businessUnitCode', businessUnitCode);
        fetch('/change-agency', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString()
        }).then(response => {
            if (response.ok) {
                return response.json().then(() => {
                    document.location.reload();
                });
            }
            return null;
        }).catch(error => {
            console.error(error);
        });
    }
}
