import $ from 'jquery';

let video = $('.video-footer'),
    buttonPlay = $('.playVideo');

function playVideo(button, element){
    button.addClass('out');
    element.trigger('play').attr('controls', true);
}

$(document).ready(function () {
    buttonPlay.click(function (e){
        playVideo($(e.currentTarget), video);
    });
    video.click(function (e){
        playVideo(buttonPlay, $(e.currentTarget));
    });
});
